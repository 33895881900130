@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,600;1,400;1,600&display=swap');

html, body, label {
  font-family: 'Roboto', sans-serif;
}

body {
  background-color: #161616;
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #202639;
}

*::-webkit-scrollbar-thumb {
  background-color: #004ED8;
  border-radius: 15px;
  border: 2px solid #202639;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #2563EB;
  border-radius: 15px;
  border: 2px solid #202639;
}